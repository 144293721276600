<template>

    <!-- 音视频操作 -->
    <div class="media">

      <!-- 音频 -->
        <!-- @click="pauseLocalAudio" -->
      <my-icon
        v-if="localUser.isUseHuaTong"
        :iconName="`icon-volume-${localUser.volume || 0}`"
        @click="() => handle('pauseLocalAudio')"

        :customStyle="{'opacity': micDisabled ? '0.3' : '1'}"
        fontSize="48"
      >
      </my-icon>

        <!-- @click="resumeLocalAudioHandle" -->
      <my-icon
        v-else
        iconName="icon-mic-close"
        @click="() => handle('resumeLocalAudioHandle')"


        :customStyle="{'opacity': micDisabled ? '0.3' : '1'}"
        fontSize="48"
      >
      </my-icon>


      <!-- 视频 -->

        <!-- @click="stopLocalVideo" -->
      <my-icon
        v-if="localUser.isUseShiPin"
        iconName="icon-camera-open"
        @click="() => handle('stopLocalVideo')"

        :customStyle="{'opacity': cameraDisabled ? '0.3' : '1'}"
        fontSize="48"
      >
      </my-icon>


        <!-- @click="startLocalVideo" -->
      <my-icon
        v-else
        id="openLocalVideoBtn"
        iconName="icon-camera-close"
        @click="() => handle('startLocalVideo')"

        :customStyle="{'opacity': cameraDisabled ? '0.3' : '1'}"
        fontSize="48"
      >
      </my-icon>

    </div>

</template>



<script>

  import { ROLE_CODE } from "@/constant/index";
  import { loganLog } from '@/utils/log';

  import { debounce } from 'lodash';
  import { MEDIA_THROTLE_TIME } from "@/constant/index";

  export default {

    data() {
      return {
        cameraDisabled: false,
        micDisabled: false,
        switchCameraDisabled: false
      }
    },

    computed: {


      localUser() {
        return this.$store.getters["member/getUser"](this.$configs.peerId);
      },

    },


    created() {
      // 监听其他组件 抛出的指令
      this.$eventBus.$on('unmute', () => {

        if(this._checkResumeLocalAudioAuth()) {
          this.resumeLocalAudio();
        } else {
          this.$toast(this.$t('meeting.noVoiceFail'));
        }

      });
      this.$eventBus.$on('switch-camera', this.switchCamera);

      this.$eventBus.$on('start-local-video', this.startLocalVideo);
      this.$eventBus.$on('stop-local-video', this.stopLocalVideo);
      this.$eventBus.$on('create-audio-transport', this.createAudioTransport);

      this.sdk.on('localVideoTrackMute', (data) => {
        loganLog('[debug info] localVideo Muted', data)
      })

      this.sdk.on('localVideoTrackUnmute', (data) => {
        loganLog('[debug info] localVideo unMuted', data)
        // const isIos = this.$utils.isIos();
        const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
        if (isIOS) {
          this.stopLocalVideo()
          setTimeout(() => {
            this.startLocalVideo()
          }, 400);
        }
      })
    },

    methods: {

      // 用来 实现防抖
      handle: debounce(function(key) {
        if(this[key]) this[key]();
      }, MEDIA_THROTLE_TIME,{ leading: true, trailing: false}),
      /******* ------------- 视频 ------------- *********/

      startLocalVideo() {

        if(this.cameraDisabled) return;
        this.cameraDisabled = true;

        this.$eventBus.$emit('open-camera-start'); // 显示 loading


        this.$store.commit("member/updateUser", {
          userId: this.$configs.peerId,
          isUseShiPin: true,
        });

        const _start = async () => {
          const { userId } = this.localUser;
          // 调用 SDK
          const videoEl = document.querySelector(`.speaker-view-video-${userId}`);
          // 创建 Clone Video
          const cloneVideo = document.querySelectorAll(`.video-${userId}`);

          try {

            try {
              await this.sdk.rtc.startLocalVideo(null);
            } catch (error) {
              console.error('startLocalVideo Error:',error)
            }

            this.cameraDisabled = false;

            this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading

            this.addStreamTootherLocal();
            const local = document.querySelectorAll('.user-video-localEle')
            for(let i = 0; i < local.length; i++) {
              try {
                // local[i].srcObject = videoEl.srcObject;
                // local[i].play();
                await this.sdk.rtc.cloneLocalVideo(local[i])
                loganLog('[debug info] startLocalVideo clone to other', local[i]);
              } catch(e) {
                loganLog('[debug info] startLocalVideo error', e);
                console.error('cloneLocalVideo Error:',e,local[i])
              }

            }

            videoEl.addEventListener('loadedmetadata', async () => {
                loganLog('[debug info] startLocalVideo() | 视频关键帧装载完毕！ loadedmetadata')
                try {
                  await videoEl.play();
                } catch (error) {
                   console.error('loadedmetadata Play Error:',error,videoEl)
                }
              })

            videoEl.addEventListener('pause', () => {
              // console.log('[debug info] startLocalVideo() | 视频 被莫名暂停了！ paused')
              setTimeout(async () => {
                try {
                  //  await videoEl.play();
                  //  loganLog('[debug info] startLocalVideo() | pause！ setTimeout play')
                  this.$eventBus.$emit('open-camera-start'); // 显示 loading

                  console.log('触发了会中video pasuse 事件')

                  await this.sdk.rtc.stopLocalVideo();
                  await this.sdk.rtc.startLocalVideo(videoEl);
                  this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading
                  loganLog('[debug info] startLocalVideo() | pause！ restartLocalVideo finish')

                   // 同步视频流 - 至其他的本地视频
                   this.addStreamTootherLocal()
                   
                   //同步下美颜预览的视频流
                   this.$eventBus.$emit('face-video-clone')
                } catch (error) {
                  this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading
                  console.error('pause Play Error:',error,videoEl)
                }
                
                // const videoE = document.querySelector(`.speaker-view-video-${userId}`);
                // try {
                //   cloneVideo.srcObject = videoE.captureStream()
                // } catch(e) {
                //   const cloneVideo = document.querySelector(`.video-${userId}`);
                //   const videoE = document.querySelector(`.speaker-view-video-${userId}`);
                //   cloneVideo.srcObject = videoE.srcObject
                // }
                // console.log('[debug info] startLocalVideo() | 执行 clone Object！', cloneVideo)
              }, 500);
            })



          } catch (error) {
            console.error('会中开启视频报错了',error)
            this.cameraDisabled = false;
            this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading

          }

        }

        this.$nextTick(_start)

        // hack 放开 按钮可点击，loading
        setTimeout(() => {
          this.cameraDisabled = false;
          this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading
        }, 3000)
      },

      // 给另一个自己添加 local stream
      async addStreamTootherLocal() {

        const { userId } = this.localUser;
        // 调用 SDK
        // const videoEl = document.querySelector(`.speaker-view-video-${userId}`);
        let other_local_el = document.querySelectorAll(`.video-${userId}`);

        for (let index = 0; index < other_local_el.length; index++) {
          let element = other_local_el[index];

          if(element) {
            try {
              await this.sdk.rtc.cloneLocalVideo(element);
              // element.srcObject = videoEl.srcObject;
              await element.play();
            } catch (error) {
              console.error('[addStreamTootherLocal] cloneLocalVideo Error:',error,element)
            }
          }

        }



      },

      async stopLocalVideo() {
        if(this.cameraDisabled) return;
        this.cameraDisabled = true;
        try {
          // 调用 SDK
          await this.sdk.rtc.stopLocalVideo()

          this.cameraDisabled = false

          this.$store.commit("member/updateUser", {
            userId: this.$configs.peerId,
            isUseShiPin: false,
          });

        }catch(e) {
          this.cameraDisabled = false
        }
        // hack 放开 按钮可点击，loading
        setTimeout(() => {
          this.cameraDisabled = false;
        }, 3000)
      },


      async switchCamera(cb) {

        if(this.cameraDisabled) return;

        try {

          this.$eventBus.$emit('open-camera-start'); // 显示 loading

          await this.sdk.rtc.switchCamera(); // 这个异步有问题

          this.startLocalVideo();

          cb && cb()

          // 给另一个自己 画面赋值
          setTimeout(() => {
            this.addStreamTootherLocal();

            this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading

          }, 50)

        } catch (error) {
          console.error('local item error', error);

          this.$eventBus.$emit('open-camera-finish'); // 隐藏 loading
        }
      },

      /******* ------------- 音频 ------------- *********/
      createAudioTransport() {

        const { isUseHuaTong } = this.localUser;
        loganLog(`local createAudioTransport isUseHuaTong: ${isUseHuaTong}`);

        this.sdk.rtc.startLocalAudio(!isUseHuaTong); // mute || unmute

      },
      resumeLocalAudioHandle() {
        if(this._checkResumeLocalAudioAuth()) {
          this.resumeLocalAudio();
        } else {
          this.$toast(this.$t('meeting.noVoiceFail'));
        }
      },

      _checkResumeLocalAudioAuth() { // 检测能否 开启音频


        // 不能开启音频
        const { allowSelfUnmute } = this.$store.state.meet;
        const { roleCode } = this.localUser;

        if (
          roleCode == ROLE_CODE.USER &&
          allowSelfUnmute == 0
        ) {
          return false
        };

        return true

      },

      resumeLocalAudio() {
        if(this.micDisabled) return;

        this.micDisabled = true;

        this.$store.commit("member/updateUser", {
          userId: this.$configs.peerId,
          isUseHuaTong: true,
        });

        (async () => {

          try {
            await this.sdk.rtc.muteLocalAudio(false);
            this.micDisabled = false

          } catch (error) {
            this.$eventBus.$emit('unmute-audio-error', error)
            this.micDisabled = false
          }
        })()

        // hack 放开 按钮可点击，loading
        setTimeout(() => {
          this.micDisabled = false;
        }, 3000)

      },
      pauseLocalAudio() {
        if(this.micDisabled) return;

        this.micDisabled = true;

        this.$store.commit("member/updateUser", {
          userId: this.$configs.peerId,
          isUseHuaTong: false,
        });

        (async () => {

          try {
            await this.sdk.rtc.muteLocalAudio(true);
            this.micDisabled = false

          } catch (error) {
            this.micDisabled = false
          }
        })()

        // hack 放开 按钮可点击，loading
        setTimeout(() => {
          this.micDisabled = false;
        }, 3000)
      },

    }
  }

</script>

<style lang="less" scoped>
  .media {
    width: 208px;
    height: 80px;
    background: #F5F5F5;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 40px;

    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }




</style>
