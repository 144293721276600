<template>
  <van-popup
    :value="show"
    position="top"
    :overlay="false"
    :lock-scroll="false"
  >
  <div class='top'>
    <div class="left">
      <div
        v-if="localUser.isUseShiPin"
        class="icon-wrapper mg-r-32"
        @click="switchCamera"
      >
        <my-icon
          fontSize="48"
          iconName="icon-camera"
        ></my-icon>
      </div>
      <div
        v-if="switchOrientationShow"
        class="icon-wrapper"
        @click.stop="switchLandscape"
      >
        <my-icon
          fontSize="48"
          iconName="icon-switch-orientation"
        ></my-icon>
      </div>
    </div>
    <div class="time-wrapper">
      <span v-if="maturating" class="color-red time"> {{$t('meeting.haveTime')}}{{ durationText }}</span> 
      <span v-else class="time">{{ durationText }}</span> 
    </div>
    <div
      class="icon-wrapper"
      @click="showExit"
    >
      <my-icon
        fontSize="48"
        iconName="icon-exit"
      ></my-icon>
    </div>
    
  </div>
  <!-- 字段cloudRecordState，0:未开始 10:开始云录制 20: 暂停云录制 30: 恢复云录制 40::结束云录制  11录制准备中  31恢复录制准备中 -->
  <div class='recoding' v-if='cloudRecorState == 11 || cloudRecorState == 31'>
    <div class='recoding_style'>
      <img src="../../../assets/images/recod.png" alt="">
      <p>云录制中</p>
    </div>
  </div>
  

  </van-popup>
</template>

<script>
import moment from 'moment'
import { getLocalDeviceInfo } from "@/utils/device";
import { ROLE_CODE } from "@/constant/index";

import { debounce } from 'lodash';
import { MEDIA_THROTLE_TIME } from "@/constant/index";

export default {

  props: {
    showToolbar: Boolean,
    orientation: String

  },

  mounted() {
   
    //该判断是是否勾选参会时长，小程序入会后就显示入会时长需去掉该判断
    this.startTime();

    this.$eventBus.$on('maturity', this.startMaturity);
  },
  data() {
    return {
      timer: null, // setInterval 会场时长 和倒计时共用一个
      maturating: false, // 是否开启了倒计时
      duration: 0, // 会议进行的时长
      durationText: '',
    }
  },
  computed: {
    show() {
      return this.showToolbar
    },

    localUser() {
      return this.$store.getters['member/getUser'](this.$configs.peerId)
    },
    cloudRecorState() {
      console.error('小程序当前录制状态',this.$store.state.meet.cloudRecorState);
      return this.$store.state.meet && this.$store.state.meet.cloudRecorState
    },

    switchOrientationShow(){
      let userMain = this.$store.getters["member/userMain"];
      
      if(!userMain) return false;
      if(userMain.sharePaused) return false; // 共享暂停 直接收起
      
      if(userMain.isShare) return true;
      if(userMain.isUseShiPin) return true;

      return false

    }



  },

  methods: {
    
    switchCamera: debounce(function() {
      this.$eventBus.$emit('switch-camera');
      console.log('debounce');
    }, MEDIA_THROTLE_TIME,{ leading: true, trailing: false}),
    showExit() {
      this.$eventBus.$emit("show-module", 'showExit')
    },

    // 会议计时
    startTime() {
      this.timer = setInterval(() => {
        this.duration++;
      },1000)
    },

    // 会议倒计时
    startMaturity(remainingTime) {
      if(this.maturating) return;

      clearInterval(this.timer);

      this.maturating = true; //只接收一次 倒计时通知
      // 提示
      const localUser = this.$store.getters["member/getUser"](this.$configs.peerId);
      if(localUser.roleCode == ROLE_CODE.HOST) {
        this.$dialog.alert({
          message: this.$t('meeting.lijiEnd') + `${remainingTime}` + this.$t('meeting.inMinutes'),
          confirmButtonText: this.$t('login.know')
        })
      }

      this.duration = remainingTime * 60;
      this.timer = setInterval(() => {
        this.duration--
        if(this.duration <= 0) clearInterval(this.timer);
      },1000)
    },

    // 格式化时间
    formatTime(val) {
      const time = moment.duration(val, 'seconds');
      let hours = time.hours(), minutes = time.minutes(), seconds = time.seconds();

      return moment({h: hours, m: minutes, s: seconds}).format('HH:mm:ss');
    },

    switchLandscape() {
      //记录横竖屏切换
      this.$store.commit("meet/updateGlobalMeetState", {
        isLandscape: true
      })
    }
  },

  watch: {
    duration(newVal) {
      this.durationText = this.formatTime(newVal)
    },

  },

  destroyed() {
    clearInterval(this.timer)
  }

}
</script>

<style lang="less" scoped>

  .van-popup {
    width: 100%;
    /* height: 120px; */
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    background: transparent;
    position: absolute;
  }
  .top{
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 0;

    .left {
        display: flex;
      }
  .icon-wrapper {
    width: 72px;
    height: 72px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-wrapper {
    display: flex;
    align-items: center;

    .time {
      line-height: 36px;
      padding: 0 21px;

      background: rgba(0, 0, 0, 0.45);
      border-radius: 18px;

      min-width: 90px;
      margin-left: 5px;
    }

    .color-red {
      color: rgb(173, 29, 29);
    }
  }

  .mg-r-32 {
    margin-right: 32px;
  }
  }
.recoding{
  margin-top:32px;
  .recoding_style{
    max-width: 240px;
    min-width: 184px;
    width: auto;
    height: 60px;
    background: #FFFFFF;
    border-radius: 0px 100px 100px 0px;
    padding:14px 24px;
    box-sizing:border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img{
      width:32px;
      height:32px;
    }
    p{
      font-size: 24px;
      color: #333333;
      line-height: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
}

 

</style>
