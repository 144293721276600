<template>
   <!--- 全体静音弹窗 --->
    <van-dialog 
      v-model="showAllMuteDialog"
      :confirmButtonText="$t('meeting.allMute')"
      confirmButtonColor="#1DAF69"
      :before-close="beforeCloseHandle"
      class="mute-win"
      show-cancel-button>
      <div class="title">{{$t('meeting.newVoice')}}</div>
      <van-checkbox
        class="mute-checkbox" 
        @change="allowSelfUnmuteChange"
        v-model="allowSelfUnmute"
        checked-color="#1DAF69"> {{$t('login.relieve')}}</van-checkbox>
    </van-dialog>
</template>

<script>
import { fetchMeetControl } from '@/api/meet'
import debounce from 'lodash.debounce'
import { CONTROL_COMMAND, THROTLE_TIME } from "@/constant/index";
import { loganLog } from "@/utils/log"
export default {
  data() {
    return {
      showAllMuteDialog: false,
      allowSelfUnmute: true,
    }
  },
  props: {
    showAllMuteWin: Boolean
  },

  computed: {
  },
  watch: {
    showAllMuteWin: {
      handler(value) {
        this.allowSelfUnmute = Boolean(this.$store.state.meet.allowSelfUnmute)
        this.showAllMuteDialog = value
      },
      immediate: true
    } 
  },
  computed: {
  },
  mounted() {
  },
  
  methods: {
     //限制按钮点击频率
     debounceHandle: debounce(fn => fn && fn(), THROTLE_TIME, { leading: true, trailing: false }),

    async allowSelfUnmuteChange(e) {
      // let allowSelfUnmute;
      let command;

      if (e) {
        // allowSelfUnmute = 1;
        command = CONTROL_COMMAND.ALLOW_SELF_UNMUTE;
      } else {
        // allowSelfUnmute = 0;
        command = CONTROL_COMMAND.FORBID_SELF_UNMUTE;
      }
      try {
        await fetchMeetControl({
          command
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|允许成员自我解除静音')
      }

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   allowSelfUnmute,
      // });
    },

    /**
     * 全体静音
     */
    async setAllMuteHandle(cb) {
      try {
        let command = CONTROL_COMMAND.ALL_FORCE_MUTE
        if (this.allowSelfUnmute) {
          command = CONTROL_COMMAND.ALL_UNFORCE_MUTE
        }

        await fetchMeetControl({
          command
        }, this.$configs)

        cb && cb()
      } catch (error) {
        console.log(error)
        loganLog(error, '会控异常|全体静音')
      }
    },

    beforeCloseHandle(action, done) {
      if (action === 'confirm') {
         this.setAllMuteHandle(() => {
           done()
           this.$emit('close')
         })
      } else {
        done()
        this.$emit('close')
      }
    },

  }
};
</script>

<style lang="less" scoped>
.mute-win {
     width: 580px;
     .title {
        font-size: 36px;
        font-weight: 400;
        color: #000000;
        text-align: center;
        margin-top: 48px;
        padding-left: 50px;
        padding-right: 50px;
     }
     .mute-checkbox {
       margin-top: 24px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-bottom: 46px;
     } 
     

} 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
/deep/ .van-checkbox__icon {
  font-size: 38px;
}
/deep/ .van-checkbox__label {
  font-size: 28px;
  font-weight: 400;
  color: #000000;
}
</style>
