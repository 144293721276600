import MessageStatusIcon from '@/components/message/message-status-icon.vue'
import {emojiMap, emojiName, emojiUrl} from '@/utils/emojiMap'
import {decodeText} from '@/utils/decodeText'
import {mapState} from 'vuex'
// import {getTimeString} from '@/utils/date'
import {formatdate} from '@/utils/date'
import cryptoRandomString from 'crypto-random-string'
import { strMiddleEllipsis } from "@/utils/str"
import { loganLog } from "@/utils/log"
import { wordsFilter} from '@/api/meet'
import { ImagePreview } from 'vant';

export default {
  components: {
    MessageStatusIcon
  },
  directives: {
    scroll: {
      inserted(el) {
        el.scrollIntoView()
      }
    }
  },
  created() {
    this.lastShowTimeStamp = 0

    this.$store.commit('setChatInfo', {
      userID: this.$configs.userId
    })
    // console.log('this ', this.sdk)
  },
  updated() {
    this.keepMessageListOnButtom()
  },
  // props: {
  //   userinfo: {
  //     type: Object,
  //     default: function () {
  //       return {}
  //     }
  //   }
  // },
  watch: {
    groupSendMsg(newMsg, msg) {
      if (newMsg !== msg) {
        clearInterval(this.interval)
        this.showGroupMsg = true
        this.interval = setInterval(() => {
          clearInterval(this.interval)
          this.showGroupMsg = false
        }, 10000)
      }
    }
  },
  computed: {
    ...mapState({
      currentMessageList: state => state.conversation.currentMessageList,
      // likeCount: state => state.conversation.likeCount,
      // chatInfo: state => state.conversation.chatInfo,
      userID: state => state.conversation.chatInfo.userId
    }),
    showChatPop () {
      //是否展示聊天半屏
      return this.$store.state.meet.showChatPop
    },
    contentList() {
      return (text) => {
        return decodeText(text)
      }
    },
    /**
     getDate () {
      return (message) => {
        return getFullDate(new Date(message.time * 1000))
      }
    }, */
    getGroupTipContent() {
      return (message) => {
        return message.payload.text
      }
    }
  },
  filters: {
    formatdate (time) {
      let t = parseInt(time)
      let date = new Date(t * 1000)
      return formatdate(date, 'hh:mm:ss')
    }
  },
  data() {
    return {
      userinfo: {},
      chatContent:'',
      preScrollHeight: 0,
      lastShowTimeStamp: 0,
      onLineUsers: [], // 在线用户
      specifyUsers: [], // 指派用户，不包括自己
      emojiMap: emojiMap,
      emojiName: emojiName,
      emojiUrl: emojiUrl,
      //messageContent: '',
      // specified: '', // @用户的id，id用逗号连接
      focus: false,
      onLineNum: 0,
      seq: 0,
      showPrivateArea: false, // 是否显示私聊区域
      privateData: {}, // 私聊的数据信息
      showAtTip: false, // 是否显示@提示
      msgId: '', // 消息id，@用户后用于定位消息所在的位置
      interval: null,
      groupSendMsg: '', // 群发消息
      showGroupMsg: false, // 是否显示群发消息
      isShowScrollButtomTips: false,
      errorTips:'',
      typeInfo:'bullet',
      hasSelfSended:false,
      isShowEmoj:false,
      userEvent: false, // 是否开启用户进入或退出房间
      isMouseInDialog: true, //是否鼠标在弹框里面

      isLoadHistoryMessage: false
    }
  },

  mounted() {
    console.log('[debug info] mounted', this.currentMessageList)


  },

  methods: {
    keydownHandle(e) {
      if (e.keyCode == 13) {
        if (this.showChatPop && this.chatContent) {
          this.sendTextMessage()
        }
      }
    },

    initEnterHandle() {
      window.addEventListener('keydown', this.keydownHandle)
    },
    removeEnterHandle() {
      window.removeEventListener('keydown', this.keydownHandle)
    },
    sortKey(array, key) {
      return array.sort(function (a, b) {
        let x = Number(a[key])
        let y = Number(b[key])
        return y < x ? 1 : -1
      })
    },
    imagepreview(src){
      ImagePreview([src]);
    },
    getHistoryMessage() {

      this.sdk.im.getHistroyMsg(
        this.roomId
      ).then((data) => {
        // this.isLoadHistoryMessage = true
          // console.log('[debug info] getHistoryMessage', data)
        this.$store.commit('resetMessageList')

        const historyDatas = this.sortKey(data, 'timestamp')
        for (let item of historyDatas) {
          let timestamp = item.timestamp
          console.log('[debug info] timestamp', timestamp.length)
          if (timestamp.length === 13) { // 13位时间戳，转换为10位s
            timestamp = parseInt(timestamp / 1000)
          }
          item.payload = {
            text: item.text
          }
          if (item['contentType'] == 1) {
            // 文字
            const message = {}
            message.nick = item['nickname']
            message.avatar = item['avatar']
            message.payload = item.payload
            message.type = item['contentType']
            message.status = 'send'
            message.seq = item.seq || item.timestamp
            message.uid = item['uid']
            // message.to = this.chatInfo.groupId
            message.timestamp = timestamp
            message.id = item['id']
            message.msgType = item['type'] // 消息类型，0是单聊，2是群发消息
            this.$store.commit('pushCurrentMessageList', {...message , notLock: true})
          } else if (item['contentType'] === 2) {
            // 图片
            this.onImgMessageReceived(item)
          }

        }
      }).catch(error => {
        console.log(error)
      })
    },

    scrollToBottom() {
      const inlineEl = this.$refs['message-list-inline']
      const messageListEl = this.$refs['message-list']

      if (inlineEl && messageListEl) {
        messageListEl.scrollTop = inlineEl.offsetHeight
      }
    },

    initListener() {
      // 收到文本消息回调
      this.sdk.im.onEvent(this.sdk.im.imSDK.EVENT.TEXT_MESSAGE_RECEIVED, this.onTextMessageReceived)
      // 收到图片消息回调
      this.sdk.im.onEvent(this.sdk.im.imSDK.EVENT.IMG_MESSAGE_RECEIVED, this.onImgMessageReceived)
      // 收到提示消息回调
      this.sdk.im.onEvent(this.sdk.im.imSDK.EVENT.SOCKET_CONNECT_FAIL, this.onSocketConFail)
      // 踢出房间回调
      this.sdk.im.onEvent(this.sdk.im.imSDK.EVENT.KICKED_OUT_RECEIVED, this.onKickedOutReceived)


      this.sdk.on('onEnteredMeeting', this.getHistoryMessage)
    },

    /**
     * 文本消息回调
     */
    onTextMessageReceived(data) {
      // console.error('收到文本消息回调-----',data)
      this.receiveMsgBody(data, 1)


    },

    /**
     * 收到图片消息回调
     */
    onImgMessageReceived(data) {

      this.receiveMsgBody(data, 2)
    },
     /**
     * 发送图片消息回调
     */

    receiveMsgBody(data, type) {
      let message = {
        payload: {
          text: ''
        }
      }

      let timestamp = data['timestamp']
      if (String(timestamp).length === 13) { // 13位时间戳，转换为10位s
        timestamp = parseInt(timestamp / 1000)
      }

      message.nick = data['nickname']
      message.avatar = data['avatar']
      message.payload.text = data['text']
      message.type = type
      message.status = 'send'
      message.seq = data['seq']
      message.uid = data['uid']
      // message.to = this.chatInfo.groupId
      message.timestamp = timestamp
      message.id = data['id']
      message.msgType = data['type'] // 消息类型，0是单聊，2是群发消息
      message.notLock = true 

       //刚进入会议获取历史记录不展示小红点
      //hasSelfSended：自己在弹幕或聊天页面有发送消息
      //showChatPop：聊天面板是否展开，未展开就展示
      //typeInfo：面板发送消息 bullet，聊天页面发送page,否则第一次进入历史记录获取信息history
      //(this.typeInfo == "bullet" && message.uid != this.userID) 弹幕发送不展示小红点

      if (!this.showChatPop && message.uid != this.userID){
        //展示新消息提醒
        this.$store.commit("meet/updateGlobalMeetState", {
          showNewChatTips: true
        })
      }
      this.$store.commit('pushCurrentMessageList', message)
    },
    /**
     * 收到管理员群发消息回调
     */
    onManagerGroupSendMsgReceived(data) {
      this.groupSendMsg = data['text']
    },

    /**
     * 踢出房间
     */
    onKickedOutReceived() {
      this.$store.commit('showMessage', {
        message: this.$t('meeting.goRoom'),
        type: 'error'
      })
    },

    /**
     * 用户进入和离开聊天室回调
     */
    onUserTips(data) {
      if (!this.userEvent) {
        return
      }
      console.log(data)
      let message = {
        payload: {
          text: ''
        }
      }
      message.payload.text = data['text']
      message.id = data['id']
      message.type = 'Live-tips'
      message.timestamp = data['timestamp']
      this.$store.commit('pushCurrentMessageList', message)
    },

    /**
     * 网络异常,socket断开了连接
     */
    onSocketConFail() {
      console.error('socket断开了链接----')
    },

    /**
     * 显示用户列表
     */
    onShowUsers() {
      this.requestUserList(0)
    },
    /**
     * @用户
     */
    // atUser(item) {
    //   this.chatContent += '@' + item['nickname'] + ' ' || ''
    //   if (this.specified === '') {
    //     this.specified += item['uid']
    //   } else {
    //     this.specified += ',' + item['uid']
    //   }
    // },
    onShowAtTip() {
      const atMsgDom = document.getElementById(this.msgId)
      if (atMsgDom) {
        let messageListNode = this.$refs['message-list']
        messageListNode.scrollTop = atMsgDom.offsetTop
      }
      this.showAtTip = false
      this.msgId = ''
    },
    /**
     * 私聊用户
     */
    privateTalkToUser(item) {
      // this.privatePrefixMsg = `发送至${item['nickname']}(私聊)`
      this.privateData = item || {}
      this.showPrivateArea = true
      console.log(item)
    },

    onCanclePrivateChat() {
      this.showPrivateArea = false
      this.privateData = {}
    },
    /**
     * 如果uid是用户自己的话就是我，如果不是就用nickname
     */
    getUserName(item) {
      if (item['uid'] === this.userID) {
        // return '我'
        return this.$t('meeting.myChat')
      }
      return strMiddleEllipsis(item['nick'], 10, 3, 2)
    },
    onScroll({target: {scrollTop}}) {
      let messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }

      if (this.preScrollHeight - messageListNode.clientHeight - scrollTop < 20) {
        this.isShowScrollButtomTips = false
      }
    },
    // 如果滚到底部就保持在底部，否则提示是否要滚到底部
    keepMessageListOnButtom() {
      let messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }
      //添加鼠标移出弹框，别人发送新消息自动下拉到最后一条
      // if (!this.isMouseInDialog) {
      //   messageListNode.scrollTop = messageListNode.scrollHeight + 60
      //   this.preScrollHeight = messageListNode.scrollHeight
      //   return
      // }
      // 距离底部20px内强制滚到底部,否则提示有新消息
      // if (this.preScrollHeight - messageListNode.clientHeight - messageListNode.scrollTop < 20) {
      //   this.$nextTick(() => {
      //     messageListNode.scrollTop = messageListNode.scrollHeight + 40
      //   })
      //   this.isShowScrollButtomTips = true
      // } else {
      //   this.isShowScrollButtomTips = true
      // }
      // this.preScrollHeight = messageListNode.scrollHeight
      if (this.preScrollHeight - messageListNode.clientHeight - messageListNode.scrollTop < 20) {
        this.$nextTick(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight + 40
        })
        this.isShowScrollButtomTips = true
      }
    },
    getUserListHandler(tab, event) {
      this.requestUserList(1)
    },
    requestUserList(flag) {
      // flag:0,表示@和私聊用户列表， flag:1表示所有用户
      this.sdk.im.getUserList(this.roomId).then(data => {
        let tempUserData = data || []
        if (flag === 0) {
          this.specifyUsers = tempUserData.filter(item => item.uid !== this.userID)
        } else {
          this.onLineUsers = tempUserData
          this.onLineNum = this.onLineUsers.length
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleLine() {
      this.chatContent = this.chatContent + '\n'
    },
    beforeUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png')
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error(this.$t('meeting.imgTipsOne'))
      }
      if (!isLt2M) {
        this.$message.error(this.$t('meeting.imgTipsTwo'))
      }
      return isJPG && isLt2M
    },
    /**
     * 上传图片到服务器
     * @param params
     */
    onUploadPic(params) {
      let file = params.file
      if (!file) return
      let originName = file.name
      const uploadHost = process.env.upload_host
      const options = {
        name: originName,
        busId: 'ybCloud',
        body: file,
        domain: uploadHost + '/upload/getsignature'
      }
      cloudCos.putObject(options, res => {
        // console.log(res)
        if (res && res.data) {
          const imgUrl = res.data.Location
          if (JSON.stringify(this.privateData) === '{}') { // 如果不是私聊的情况下，会发送文本框里面的消息
             if (this.chatContent !== '' || this.chatContent.trim().length !== 0) {
              this.sendMsgBody(1, this.chatContent)
            }
          }
          this.sendMsgBody(2, imgUrl)
        }
      })
    },
    /**
     * 重发请求
     */
    onResend(message) {
      // this.$store.commit('pushCurrentMessageList', message)

      if (JSON.stringify(this.privateData) !== '{}') {
        // 私聊先不做重发请求
      } else {
        this.sdk.im.sendTextMessage(this.roomId, {
          type: message.type, // 1是文字，2是图片
          payload: message.payload.text,
          seq: message.seq,
          timestamp: Date.now(),
          // specified: this.specified, // @发送消息的用户
          ext: null
        }).then(() => {
          console.log('send text message success')
          message.status = 'send'
          this.$store.commit('pushCurrentMessageList', message)
        }).catch(error => {
          message.status = 'fail'
          if (error.code === 10006) {
            console.log('无权限，房间可能被管理员禁言')
          } else if (error.code === 10003) {
            console.log('发送太频繁，请稍后再试')
          }
        })
      }
    },
    async sendTextMessage(type) {
      // window.scroll(0, 0) // ios键盘回落
      // if (!this.showMessage) {
      //   this.showMessage = true
      // }
      this.hasSelfSended = true; //记录自己发送过
      this.typeInfo = type;
        let that = this;
        this.errorTips = "";

        if (this.chatContent) {
          // let len = this.$tools.gblen(this.chatContent)
          if (this.chatContent.length > 300) {
            this.$toast(this.$t('meeting.wordLimt'));
            // that.errorTips = this.$t('meeting.wordLimt');
            return
          }
        }
        if(this.chatContent === ''){
          //this.$toast(this.$t('meeting.words') + this.$t('reg.regEmpty'))
          return
        }

        try {
          await wordsFilter({ 
            bizType:'meeting',
            text:this.chatContent
          })
        } catch (error) {
          let _msg = [102,103,199001].includes(error.code) ? '消息内容存在敏感词，请检查后重试' : '消息内容' + error.msg
          this.$toast({
            className:'toastBottom',
            message:_msg
          })
          loganLog(`发送消息错误--error:${JSON.stringify(error)}，test:${this.chatContent}`)
          return
        }

      this.sendMsgBody(1, this.chatContent)
    },

    /**
     * type:1是文字，2是图片
     */
    sendMsgBody(type, msgContent) {
      let message = {
        payload: {
          text: ''
        }
      }
      message.nick = this.userinfo.nickname
      //message.avatar = this.chatInfo.avatar
      message.payload.text = msgContent//消息
      message.type = type
      message.status = 'unSend'
      message.seq = this.seq
      message.uid = this.userID
      // message.to = this.chatInfo.groupId
      message.timestamp = Math.floor(Date.now() / 1000)

      if (JSON.stringify(this.privateData) !== '{}') {
        message.msgType = 0 // 单聊消息类型
        message.toUserNick = this.privateData.nickname
      } else {
        message.msgType = 2 // 群发消息类型
      }
      this.$store.commit('pushCurrentMessageList', message)

      if (JSON.stringify(this.privateData) !== '{}') {
        this.sdk.im.sendPrivateMsg(this.roomId, this.privateData['token'], {
          type: type, // 1是文字，2是图片
          payload: msgContent,
          seq: this.seq,
          timestamp: message.timestamp,
          // specified: this.specified // @发送消息的用户
        }).then(() => {
          console.log('send private text message success')
          this.onCanclePrivateChat()
          message.status = 'send'
          this.$store.commit('pushCurrentMessageList', message)
        }).catch(error => {
          message.status = 'fail'
          if (error.code === 10005) {
            // console.log('无权限，房间可能被管理员禁言')
            this.$store.commit('showMessage', {
              message: error.msg,
              type: 'error'
            })
          } else if (error.code === 10003) {
            console.log('发送太频繁，请稍后再试')
          }
        })
      } else {
        this.sdk.im.sendMsg(this.roomId, {
          type: type, // 1是文字，2是图片
          payload: msgContent,
          seq: this.seq,
          timestamp: message.timestamp,
          // specified: this.specified, // @发送消息的用户
          ext: null
        }).then(() => {
          console.log('send text message success')
          message.status = 'send'
          this.$store.commit('pushCurrentMessageList', message)
        }).catch(error => {
          message.status = 'fail'
          message.failId = cryptoRandomString({length: 5})

          if (error.code === 10005) {
            this.$store.commit('showMessage', {
              message: error.msg,
              type: 'error'
            })
          } else if (error.code === 10003) {
            console.log('发送太频繁，请稍后再试')
          }
        })
      }

      this.seq++
      // this.$refs.chatContent.emptyText();
      this.chatContent = "";
      // this.specified = ''
    },
    /**
     * 时间显示
     * @param t
     * @returns {*}
     */
    getTimeStampText(t) {
      return getTimeString(t, true)
    },
    sendPicMessage() {

    },
    textMsg(type) {
      return type === 1
    },
    showAvatar(type) {
      return this.textMsg(type) || this.picMsg(type)
    },
    leaveMsg(type) {
      return type === 'Live-tips'
    },
    picMsg(type) {
      return type === 2
    },
    // imgError(item) {
    //   item.avatar = require('@/assets/images/im/avatar.png')
    // },
    tabClick(index) {
      // window.scroll(0, 0)    //切换tab，聊天区域滑到底部
      this.isActive = ['', '']
      this.isActive[index] = 1
      this.tabSelected = index
      if (index === 1) {
        this.getUserListHandler()
      }
    },
    exitRoom() {
      this.sdk.im.leaveRoom()
    },
    showEmojHandler(){
      this.isShowEmoj = !this.isShowEmoj
    },
    closeChatHandler(){
      this.$store.commit("meet/updateGlobalMeetState", {
        showNewChatTips: false, //关闭新消息提醒
        showChatPop: true
      })
    },
    onEnterHandler(){
      this.isMouseInDialog = true
    },
    onLeaveHandler() {
      this.isMouseInDialog = false
    }
  },
  beforeDestroy() {
    this.removeEnterHandle()
    // 收到文本消息回调
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.TEXT_MESSAGE_RECEIVED, this.onTextMessageReceived)
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.IMG_MESSAGE_RECEIVED, this.onImgMessageReceived)
    // 收到提示消息回调
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.USER_TIPS, this.onUserTips)
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.SOCKET_CONNECT_FAIL, this.onSocketConFail)
    // 收到管理员群发消息回调
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, this.onManagerGroupSendMsgReceived)
    this.sdk.im.offEvent(this.sdk.im.imSDK.EVENT.KICKED_OUT_RECEIVED, this.onKickedOutReceived)
    clearInterval(this.interval)

    this.exitRoom() // 退出房间，解决后退键不能删除历史数据的问题
  }
}
