<template>
   <div>
     <van-popup  
       round 
       closeable
       close-icon-position="top-left"
       close-icon="arrow-left"
      class="meeting-member"
      :class="isLandscape ? 'landscape-member' : 'portrait-member'"
      :value="value" 
      @input="val => this.$emit('input', val)"  
      :position="isLandscape ? 'right' : 'bottom'"
      :get-container="getContainer"
    >
      <div class="head">成员({{filterList.length}}人) <span v-if="lockedState">会议已锁定</span><div  v-if="!isOrdinaryUser" @click="showSetting = true">设置</div> 
      </div>
      <div class='content'>
        <!-- 查询 --->
        <div class='filter'>
          <van-field class='input' :class="{'active': searchMember }" center v-model="searchMember" :placeholder="choiceMicrophone? $t('meeting.searchMicrophonePerson') : $t('meeting.searchPerson')">
             <template #left-icon>
                <my-icon iconName="icon-sousuo_32_hei" fontSize="32"></my-icon>
             </template>
             <template #right-icon v-if="searchMember">
                <my-icon @click="onClearFilter" iconName="icon-xitonglan_guanbi_48_hei" fontSize="32"></my-icon>
             </template>
          </van-field>
          <div class="right" v-if="!searchMember" @click="onShowFilterMenu">
             <my-icon iconName="icon-tongyong_shaixuan_48" fontSize="48"></my-icon>
             <span>{{$t('meeting.filter')}}</span>
          </div>
        </div>
        
        <!-- 列表 --->
        <div class="member-list">
         <van-list
            finished-text="没有更多了" v-if="filterList.length > 0">
            <template v-for="(member, index) in filterList">
              <div class="flex-item" v-if="!choiceMicrophone?true:member.isUseHuaTong?true:false"
                :class="{ 'active': currentMember.userId == member.userId }" 
                :key="index" 
                @click="onClickMemberHandle(member, index)"
              >

               <img :src="member.avatarUrl" v-if="member.avatarUrl" class="avatar"/>
               <div v-else class="avatar text">
                {{ member.userName | nameFirstFilter }}
               </div>
               <div class="info">
                <div :class="{'small': member.isShare || (member.isRecord && !member.recordPaused) || member.isRaiseHand }" v-html='hightLight(strMiddleEllipsis(member.userName, 20, 6, 4), searchMember)'></div>
                <div>{{ getRoleText(member) }}</div>
               </div>
               <div class="btns">
                  <my-icon v-if='(cloudRecorState == 11 || cloudRecorState == 31) && getRoleIcon(member)' iconName="icon-a-icontubiaohuichangluzhi_24_hei" fontSize="48"></my-icon>
                  <my-icon v-if="member.isShare" iconName="icon-liebiao_gongxiang" fontSize="48"></my-icon>
                  <my-icon v-if="member.isRecord && !member.recordPaused" iconName="icon-luzhi_48_hei" fontSize="48"></my-icon>
                  <my-icon v-if="member.isRaiseHand" iconName="icon-jushou_48_hui" fontSize="48"></my-icon>
                  
                  <my-icon 
                    v-if="member.isUseHuaTongError"
                    iconName="icon-shengyinyichang_48_hui"
                    fontSize="48"
                  ></my-icon>
                  <my-icon v-else :iconName="member.isUseHuaTong ? 'icon-shengyin_48_hui' : 'icon-shengyin_guanbi_48_hui'" fontSize="48"></my-icon>
                  
                  <my-icon :iconName="member.isUseShiPin ? 'icon-shipin_48_hui': 'icon-shipin_guanbi_48_hui'" fontSize="48"></my-icon>
               </div>
              </div>
            </template>
          </van-list>
          <div class="member-empty" v-if="filterList.length === 0">
               <my-icon iconName="icon-sousuowujieguo" fontSize="220"></my-icon>
               <p>未找到相关成员</p>
          </div>
        </div>

        <!-- 底部会控按钮 -->
        <control-button @show-share="onMeetingInvite"></control-button>

        <!--- 会议邀请弹窗 --->
        <share v-model="showSharePop" @close="showSharePop = false"></share>

        <!-- 会控成员操作弹窗 -->
        <control-menu v-model="showMemberMenu" :isShowMore="isShowMore(currentMember)" :member="currentMember"></control-menu>
      </div>
    </van-popup>

    <!-- 会议设置 -->
    <setting v-model="showSetting"></setting>
    

     <!-- 过滤条件底部弹窗 -->
    <pop-up-bottom v-model="showFilterMenu" >
      <div class="menu-content">
        <div class="text title">{{$t('meeting.filter')}}</div>
        <div class="text" @click="changeSearchChoice(false)">
           {{$t('meeting.acquiescence')}}
          <my-icon  v-if="!choiceMicrophone"
          class="icon" 
          iconName="icon-duigou_16_lv" 
          fontSize="48" />
        </div>
        <div class="text" @click="changeSearchChoice(true)">
          {{$t('meeting.microphoneOn')}}
          <my-icon v-if="choiceMicrophone"
          class="icon" 
          iconName="icon-duigou_16_lv" 
          fontSize="48" />
        </div>
      </div>
     </pop-up-bottom>
   </div>
</template>

<script>
  import Setting from './Setting.vue'
  import PopUpBottom from '@/components/PopUpBottom/index';
  import ControlMenu from './ControlMenu';
  import ControlButton from './ControlButton';
  import Share from '@/components/Share/index'


  import '@/utils/filter'
  import { strMiddleEllipsis, hightLight } from "@/utils/str"

  import { WEIGHT_CONFIG, MAX_CONF_TIME, ROLE_CODE, CONTROL_COMMAND, THROTLE_TIME } from "@/constant/index";

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    components: {
      Setting,
      PopUpBottom,
      ControlMenu,
      ControlButton,
      Share,
    },

    data() {
      return {
        searchMember: '',
        showSetting: false,
        showMemberMenu: false,
        showFilterMenu: false,
        choiceMicrophone: false, //是否选择麦克风 过滤条件
        showSharePop: false,
        currentMember: {},
        members: [],
        data: [],
        orientation:'portrait'
      }
    },

    computed: {
       isLandscape () {
          return this.$store.state.meet.isLandscape
       },

       lockedState() {
          // 会议锁定
          return this.$store.state.meet.lockedState;
       },

       selfInfo() {
          return this.$store.getters["member/getUser"](this.$configs.peerId);
       },

       ownerId() { // 会议所有者id
         return this.$store.state.meet.ownerId
       },

       isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
       },

       isCoHost() {
         return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
       },

       isOrdinaryUser() {
         return this.selfInfo.roleCode === ROLE_CODE.USER;
       },

       ownerPasswordEnable() { // 是否有主持人密码，0否，1是
        return this.$store.state.meet.ownerPasswordEnable
       },

       userList() {
          // 成员列表
          return this.$store.state.member.userList
          //return this.$store.getters["member/getRealUserList"]
       },
       cloudRecorState() {
          console.error('小程序当前录制状态',this.$store.state.meet.cloudRecorState);
          return this.$store.state.meet && this.$store.state.meet.cloudRecorState
       },
       filterList() {
          const filterUserList = this.userList.filter(i => {
            if (!i.userName) return

            //筛选开启了话筒
            if(this.choiceMicrophone && !i.isUseHuaTong){
              return 
            } else {
              return i.userName.indexOf(this.searchMember) > -1
            }
          }).map(item => {
            let weight = 0
            const nowTime = new Date().getTime()
            let duration = nowTime - item.timeMs

            duration = duration > 0 ? duration : 0

            if (item.userId === this.selfInfo.userId) { // 是自己
              weight += WEIGHT_CONFIG.SELF
            } else if (item.roleCode === ROLE_CODE.HOST) { // 是主持人
              weight += WEIGHT_CONFIG.HOST
            } else if (item.roleCode === ROLE_CODE.CO_HOST) { // 是联席主持人
              weight += WEIGHT_CONFIG.CO_HOST
            } else {
              weight += WEIGHT_CONFIG.USER
            }

            weight = (weight * MAX_CONF_TIME) + duration

            return {
              ...item,
              userWeight: weight
            }
          }).sort((a, b) => {
            const v1 = a.userWeight
            const v2 = b.userWeight

            return v2 - v1
          })
          return filterUserList
       },
    },

    methods: {
      strMiddleEllipsis, 
      hightLight,

      closeWin() {
        this.showAllMuteWin = false
      },

      onClearFilter() {
        this.searchMember = ''
      },

      changeSearchChoice(searchChoice){
        this.choiceMicrophone = searchChoice
      },

      onClickMemberHandle(member) {

        this.currentMember = member;
        if (this.isCurrentUser(member)) {
          this.showMemberMenu = true
        } else if (this.isShowMore(member)) {
          this.showMemberMenu = true
        } 
      },

      onMeetingInvite() {
        this.showSharePop = true
      },



      //展示过滤条件
      onShowFilterMenu() {
        this.showFilterMenu = true
      },

      isCurrentUser(item) {
        return item.userId === this.$configs.peerId;
      },

      isShowMore(item) {
        // 是否显示更多按钮
        if (this.isOrdinaryUser) {
          // 普通用户不显示
          return false;
        } else if (this.isCoHost) {
          // 联席主持人

          if (item.roleCode === ROLE_CODE.HOST) {
            return false;
          } else {
            return !this.isCurrentUser(item);
          }
        } else {
          // 主持人
          return !this.isCurrentUser(item);
        }
      },
      
      getRoleIcon(item) {
        const { roleCode } = item
        if (roleCode === ROLE_CODE.USER) { // 普通用户
          return false
        } else { // 主持人，联系主持人
          return true
        }
      },
       /**
     * 显示职称信息
     */
      getRoleText(item) {

        const { roleCode } = item

        const nameConfig = {
          [ROLE_CODE.HOST]: this.$t('meeting.host'),
          [ROLE_CODE.CO_HOST]: this.$t('meeting.nohost')
        }

        if (roleCode === ROLE_CODE.USER) { // 普通用户
          return this.isCurrentUser(item) ? `( ${this.$t('meeting.me')} )` : ''
        } else { // 主持人，联系主持人

          const title = nameConfig[roleCode]

          return `(${title}${this.isCurrentUser(item) ? `,${this.$t('meeting.me')}` : ''})`
        }
      },

      getContainer() {
        return document.querySelector('.meeting-page')
      }
    }
  }

</script>

<style lang="less" scoped>
.flex_center(@align:center, @justify:center) { 
  display: flex;
  align-items: @align;
  justify-content: @justify;
}
.text_overflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.meeting-member {
   overflow: hidden;
}

.head {
      width: 100%;
      height: 88px;
      line-height: 88px;
      font-size: 32px;
      font-weight: 500;
      color: #000000;
      margin-top: 11px;
      text-align: center;
      position: relative;

    div {
      position: absolute;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      color: #1DAF69;
      top: 26px;
      right: 40px;
    }
    span {
      font-size: 32px;
      font-weight: 400;
      color: #999999;
      margin-left:16px;
    }
}

.content {
     width: 100%;

     .filter {
       width: 100%;
       height: 80px;
       padding-left: 40px;
       padding-right: 40px;
       .flex_center(center, space-between);
       .input {
         width: 534px;
         height: 60px;
         border-radius: 8px;
         font-size: 28px;
         color: #000000;
         background: #EEEEEE;
       }
       .input::placeholder {
         color:rgba(0, 0, 0, .3)
       }

       .input.active {
          width:100%;
          background: #FFFFFF;
          border: 2px solid #1DAF69;
       }

       .right {
         .flex_center();
         span {
            font-size: 28px;
            font-family: MicrosoftYaHei;
            color: #666666;
            margin-left: 8px;
            display: inline-block;
         }
       }
     }

     .member-list {
        overflow-y: auto;
        .flex-item {
          margin-top: 16px;
          height: 110px;
          width: 100%;
          padding-left: 40px;
          padding-right: 40px;
          .flex_center(center, space-between);
        }

        .flex-item.active {
          background: rgba(0, 0, 0, 0.05)
        }

        .avatar {
           width: 72px;
           height: 72px;
           border-radius: 100%;
        }
        .avatar.text {
          color: #ffffff;
          font-size: 32px;
          background-color: #1ab370;
          flex: none;
          .flex_center();
        }

        .info {
           margin-left: 24px;
           flex: 1;
        }

        .info div:nth-child(1) {
           font-size: 32px;
           color: #000000;
           line-height: 45px;
           height: 45px;
           max-width: 390px;
           .text_overflow();
        }
        .info div.small {
           max-width: 210px;
        }

        .info div:nth-child(2) {
           font-size: 24px;
           color:rgba(0, 0, 0, .6);
           line-height: 33px;
        }

        .btns {
          .flex_center(center, flex-end);
          svg {
            margin-left: 22px;
          }
          .audio-error {
            margin-left: 22px;
            background: url(~@/assets/images/member/audio-error.png) no-repeat;
            background-size: 100% 100%;
          }
        }
     }

     .member-empty {
        padding-top: 172px;
        text-align: center;
        color: #000;
        font-size: 28px;
     }
}

/********** 会控菜单弹窗 ************/
.menu-content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  .text {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }
  .text.title {
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 400;
    color: #999999;
  }
  .text.red {
    color:#F35B67
  }
  .text .icon {
    position: absolute;
    top: 24px;
    right: 159px;
  }
}
.menu-content .text:last-child {
  border-bottom: none;
}

/deep/ .input .van-field__left-icon {
    margin-top: 15px;
    margin-left: -11px;
}
/deep/ .input .van-field__right-icon {
    margin-top: 9px;
    padding: 0;
}


/******************* 横竖屏切换 样式 ********************* */
.portrait-member {
  height: 1080px;
  .member-list {
    height: 742px;
  }
}
.landscape-member {
  width: 60%;
  height: 750px;
  .member-list {
    height: 460px;
  }
}

</style>
