<template>
  <transition name="popBox">
    <div v-show="value" @click="hidePopup" class="popUpBottom" :class="{'landscape-pop' : isLandscape }">
      <transition name="popUpBottom">
        <div v-if="value" class="bottom">
          <slot></slot>
          <div class="cancel">{{$t('login.cancel')}}</div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PopUpBottom",
  data() {
    return {};
  },
  computed: {
    isLandscape () {
      return this.$store.state.meet.isLandscape
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    }
  },
  methods: {
    hidePopup() {
      this.$emit("input", false);
    },
  },

  mounted() {


    this.$nextTick(() => {
      const body = document.querySelector("body .meeting-page");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }

    });
  }
};
</script>

<style scoped  lang="less">
.popUpBottom-enter-active,
.popUpBottom-leave-active {
  transition: opacity 0.5s;
  transition: all 0.3s ease;
}
.popUpBottom-enter,
.popUpBottom-leave-to {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.popBox-enter-active,
.popBox-leave-active {
  transition: opacity 0.5s;
}
.popBox-enter,
.popBox-leave-to {
  opacity: 0;
}
.popUpBottom {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  .bottom {
    width: 100%;
    position: absolute;
    bottom: 60px;
    left: 0;
    box-sizing: border-box;
    padding: 0 32px;
    .content {
      background: #fff;
      width: 100%;
      border-radius: 12px 12px 16px 16px;
      .text {
        height: 96px;
        text-align: center;
        line-height: 96px;
      }
    }
    .cancel {
      height: 96px;
      text-align: center;
      line-height: 96px;
      border-radius: 12px 12px 16px 16px;
      background: #fff;
      margin-top: 16px;
      color: #1daf69;
      font-size: 32px;
    }
  }
}

//横屏样式
.landscape-pop .bottom{
  width: 60%;
  left: 20%;
  max-height: calc(100% - 70px);
  overflow-y: auto;
}
</style>
