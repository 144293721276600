


<template>

  <!-- :style 用来区分 mainUser height：100% -->
  <div
    class="video-wrapper"
    v-if="user"
  >
    <!-- 媒体组件 -->
    <video
      v-if="user.isUseShiPin"
      autoplay
      webkit-playsinline
      playsinline
      muted
      class="user-video-localEle"
      :class="className"
    ></video>
    <!-- userInfo -->
    <div class="user-info">
      <!-- 主持人icon 显示 -->

      <my-icon
        v-if="user.roleCode == ROLE_CODE.HOST"
        iconName="icon-host"
        fontSize="40"
      ></my-icon>
      <my-icon
        v-else-if="user.roleCode == ROLE_CODE.CO_HOST"
        fontSize="40"
        iconName="icon-cohost"
      ></my-icon>


        <my-icon
          :iconName="[user.isUseHuaTong ?
                  `icon-solid-volume-${user.volume || 0}` :
                  `icon-solid-mic-close`]"
          fontSize="32"
          style="margin-left: 2px"
        ></my-icon>
        <span>{{ user.userName }}</span>

    </div>

    <!-- 无视频占位图 -->
    <div
      v-if="!user.isUseShiPin"
      class='no-video-placeholder'
    >
      <img v-if='user.avatarUrl' :src="user.avatarUrl" class="avatar" mode="aspectFill" />
      <div v-else class="name">{{ user.userName ? user.userName.slice(0, 1) : ''  }}<div>{{user.hospital}}</div></div>
    </div>

    <!-- 在说话高亮 -->
    <div
      v-if="isSpeaking"
      class="speaking-highlight"
    ></div>


    <van-loading v-if="user && user.isUseShiPin && status == 'loading'" />

  </div>

</template>


<script>


import { ROLE_CODE } from "@/constant/index";
import { loganLog } from '@/utils/log';


export default {

    props: {
      user: Object,
      speakerView: Boolean, //是否位于演讲者视图画面
      notHighlight: Boolean // 自己作为发言方 不高亮
    },


    data() {
        return {
            ROLE_CODE,
            status: 'loading',
        }
    },

    computed: {
      className() {

        const { userId } = this.user;

        if(this.speakerView) { //一直保存的local el, 宫格视图的 local 会被销毁
          return `speaker-view-video-${userId}`
        }

        return `video-${userId}`
      },

      isSpeaking() {
        const speakUser = this.$store.getters['member/getSpeakUser'];

        if(!speakUser) return false;
        if(this.speakerView || this.notHighlight) return; // 演讲者视图不高亮

        return speakUser.userId == this.user.userId;

      }
    },

    // 不需要 发布推流的 本人画面
    mounted() {
      loganLog('[local video] mounted', JSON.stringify(this.user));

      //延迟加载 修复卡屏问题
      this.status = 'loading'
      setTimeout(() => { 
          if(!this.speakerView) this.getStream(); 
      }, 1000)

      // 监听 loading
      this.$eventBus.$on('open-camera-start', () => this.status = 'loading');
      this.$eventBus.$on('open-camera-finish', () => this.status = 'finish');
    },


    methods: {

      // 自己获取 stream，在没有主动添加的情况下, 只有 this.speakerView == false 才会执行这个方法
      async getStream() {
        let videoEl = this.$el.querySelector('video');
        let local_el = document.querySelector(`.speaker-view-video-${this.user.userId}`);

        if(local_el && local_el.srcObject && !videoEl.srcObject) {
          // videoEl.srcObject = local_el.srcObject;
          try {
             await this.sdk.rtc.cloneLocalVideo(videoEl);

             videoEl.addEventListener('pause', async () => {
                this.status = 'finish'
                console.log('[debug info] getStream() | clone Video Error Paused');
                try {
                  setTimeout(() => {
                    videoEl.play()
                  }, 700)
                } catch (error) {
                   console.error(error)
                }
              })
          } catch (error) {
             console.error(error)
          }
          this.status = 'finish'
        }

      }
    },



}
</script>


<style lang="less" scoped>

  div {
    box-sizing: border-box;
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    background: #222;

    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      
      background: #222;

      transform: rotateY(180deg);
      -webkit-transform:rotateY(180deg); /* Safari and Chrome */
      -moz-transform:rotateY(180deg); /* Firefox */

      will-change: transform;
    }

    .user-info {
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
      z-index: 9;
      transform: translateZ(0);

      height: 40px;

      background-color:rgba(0, 0, 0, .5);
      border-radius: 0px (8px) 0px 0px;
      padding-right: 8px;

      max-width:100%;



      svg {
        flex-shrink: 0;
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

    }

    .no-video-placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;

      display: flex;
      justify-content: center;
      align-items: center;

      .name {
        width: 128px;
        height: 128px;
        background: #1AB370;
        border-radius: 128px;

        text-align: center;
        line-height: 128px;

        font-size: 40px;
      }

      .avatar {
        width: 128px;
        height: 128px;
        border-radius: 100%;
      }
    }

    .speaking-highlight {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;

      border: (3px) solid #1AB370;
    }

    .van-loading {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

</style>
