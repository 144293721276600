<template>
  <div>
     <div class="action">
        <template v-for="(btn, index) in controlButtons">
          <van-button :key="index" 
          type="primary" 
          @click="btn.handle" 
          plain
          :class="{'light': btn.id === 'allMute' && isSetHighLight() }"
          class="button">{{ btn.text }}</van-button>
        </template>
     </div>

     <!-- 全体静音弹窗 -->
     <all-mute :showAllMuteWin="showAllMuteWin" @close="closeWin"></all-mute>
     <!-- 成为主持人弹窗 -->
     <become-host :dialogVisible='showBecomeHostWin' @close='showBecomeHostWin = false' />

     <!-- 底部更多弹窗 -->
    <pop-up-bottom v-model="showMoreMenu">
      <div class="menu-content">
        <div class="text" @click="btn.handle" :key="index" v-for="(btn, index) in moreButtons">
          {{ btn.text }}
        </div>
      </div>
     </pop-up-bottom>
  </div>
</template>

<script>
  import AllMute from '@/components/dialog/AllMute'
  import BecomeHost from '@/components/dialog/BecomeHost'
  import PopUpBottom from '@/components/PopUpBottom/index';

  import { ROLE_CODE, CONTROL_COMMAND, THROTLE_TIME } from "@/constant/index";
  import { fetchMeetControl } from '@/api/meet'
  import { debounce } from 'lodash'
  import { loganLog } from "@/utils/log"

  export default {
    props: {
       member: {
        type: Object,
        default: () => {}
      }
    },

    components: {
      AllMute,
      BecomeHost,
      PopUpBottom
    },

    data() {
      return {
        showAllMuteWin: false,
        showBecomeHostWin: false,
        showMoreMenu: false,
        moreButtons: []
      }
    },

    computed: {
      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId);
      },

       ownerId() { // 会议所有者id
         return this.$store.state.meet.ownerId
       },

       isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
       },

       isCoHost() {
         return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
       },

       isOrdinaryUser() {
         return this.selfInfo.roleCode === ROLE_CODE.USER;
       },

       ownerPasswordEnable() { // 是否有主持人密码，0否，1是
        return this.$store.state.meet.ownerPasswordEnable
       },

       userList() {
          // 成员列表
          return this.$store.state.member.userList
       },
      
       allMuteState() {
          return this.$store.state.meet.allMuteState;
       },

       allowSelfUnmute() {
        return this.$store.state.meet.allowSelfUnmute;
       },

       controlButtons() {

         const buttonCollection = []

         //全体静音
         if (this.isShowAllToggleMute()) {
            buttonCollection.push(
              {
                id: 'allMute',
                text: this.$t('meeting.allMute'),
                handle: this.setAllMuteClickHandle
              }
            )
         }
         
         //解除全体静音
         if (this.isShowAllToggleMute()) {
            buttonCollection.push(
                {
                  text: this.$t('meeting.silenceall'),
                  handle: this.debounceHandle.bind(this, this.setAllUnMuteHandle)
                }
            )
         }

         //全体停止举手
         if (this.isShowStopAllHand()) {
            buttonCollection.push(
              {
                text: this.$t('meeting.allStopHands'),
                handle: this.debounceHandle.bind(this, this.downAllHandHandle)
              }
            )
         }

         //收回主持人
         if (this.isShowRevokeHost()) {
           buttonCollection.push(
             {
              text: this.$t('meeting.getMain'),
              handle: this.debounceHandle.bind(this, this.revokeHostHandle)
             }
           )
         }

         //举手
         if (this.isShowBottomHand()) {
           buttonCollection.push(
             {
                text: this.$t('meeting.handsUp'),
                handle: this.raiseHandHandle
             }
           )
         }

         //手放下
         if (this.isShowBottomDownHand()) {
           buttonCollection.push(
            {
              text: this.$t('meeting.putHands'),
              handle: this.downHandHandle
            }
           )
         }
 
        //和移动端一致 不展示静音、解除静音
        //  //静音
        //  if (this.isShowBottomSelfMute()) {
        //    buttonCollection.push(
        //     {
        //       text: this.$t('meeting.audioMute'),
        //       handle: this.setMuteHandle.bind(this, this.$configs.peerId)
        //     }
        //    )
        //  }

        //  //解除静音
        //  if (this.isShowBottomSelfUnMute()) {
        //    buttonCollection.push(
        //       {
        //         text: this.$t('meeting.audioUnMute'),
        //         handle: this.setUnMuteHandle.bind(this, this.$configs.peerId)
        //       }
        //    )
        //  }


        if(this.$configs.businessType == 10007) return buttonCollection //信息采集业务不需要下面的两个按钮

         //成为主持人
         if (this.isShowBecomeHost()) {
           buttonCollection.push(
              {
                text: this.$t('meeting.becomeHost'),
                handle: this.setBecomeHostHandle
              }
           )
         }

         //邀请按钮
         if (buttonCollection.length < 3) {
            buttonCollection.push(
              {
                text: this.$t('meeting.invitation'),
                handle: this.onMeetingInvite
              }
            )
         } 
         //更多
         else if (buttonCollection.length === 3) {
            //填充更多按钮中的内容
            this.moreButtons = [
              buttonCollection[2],
              {
                text: this.$t('meeting.invitation'),
                handle: this.onMeetingInvite
              }
            ]

            buttonCollection.splice(2, 1, {
                text: this.$t('login.more'),
                handle: this.onShowMoreButton
            }) 
         }
          else if (buttonCollection.length === 4) {
            //填充更多按钮中的内容
            this.moreButtons = [
              buttonCollection[2],
              buttonCollection[3],
              {
                text: this.$t('meeting.invitation'),
                handle: this.onMeetingInvite
              }
            ]

            buttonCollection.splice(2, 1, {
                text: this.$t('login.more'),
                handle: this.onShowMoreButton
            }) 
            buttonCollection.splice(3, 1)
         }

         return buttonCollection
      }

    },

    methods: {

      closeWin() {
        this.showAllMuteWin = false
      },

      onMeetingInvite() {
        this.$emit('show-share')
      },

      onShowMoreButton() {
        this.showMoreMenu = true
      },

      //限制按钮点击频率
      debounceHandle: debounce(fn => fn && fn(), THROTLE_TIME, { leading: true, trailing: false }),
     

      isCurrentUser(item) {
        return item.userId === this.$configs.peerId;
      },

      /****** 底部按钮 显示隐藏 ****/
      isShowAllToggleMute() { // 是否显示全体静音/取消全体静音
         return this.isHost || this.isCoHost
      },

      /**
     * 设置全体静音的高亮状态
     * 0：全体解除静音  1：全体静音
     */
      isSetHighLight() {
        return (this.allMuteState && !this.isOrdinaryUser) ? true : false
      },

      isShowStopAllHand() {
      // 是否显示全部停止举手
      let isExitUserHand = false;
      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i];

        if (item.isRaiseHand) {
          // 正在举手
          isExitUserHand = true;
          break;
        }
      }

      return (this.isHost || this.isCoHost) && isExitUserHand;
    },

    isShowRevokeHost() {
      if (this.selfInfo.userId) {
        const idArr = this.selfInfo.userId.split('_')
        return !this.isHost && (this.ownerId === idArr[1])
      } else {
        return false
      }
    },

    isShowBottomHand() { // 举手
      return this.isOrdinaryUser && !this.selfInfo.isRaiseHand
    },

    isShowBottomDownHand() { // 手放下
      return this.isOrdinaryUser && this.selfInfo.isRaiseHand
    },

    isShowBottomSelfMute() { // 静音
      return this.isOrdinaryUser && this.selfInfo.isUseHuaTong
    },
    
    isShowBottomSelfUnMute() { // 解除静音
      return this.isOrdinaryUser && !this.selfInfo.isUseHuaTong
    },


    isShowBecomeHost() { // 成为主持人，注意owner永远不会显示成为主持人

      let isExistHost = false

      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i]

        if (item.roleCode === ROLE_CODE.HOST) {
          isExistHost = true
          break
        }
      }

      return this.ownerPasswordEnable && (this.ownerId !== this.$configs.userId) && !isExistHost
    },
    
    /**************  底部按钮 事件 **************** */
    /**
     * 全体静音点击事件
     */
    async setAllMuteClickHandle() {
      console.error('全体静音')

      this.showAllMuteWin = true
    },
    
    /**
     * 解除全体静音
     */
    async setAllUnMuteHandle() {
      this.$toast(this.$t('meeting.pleaceVoice'))

      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.ALL_UNMUTE
        }, this.$configs)
      } catch (error) {
        console.log(error)
        loganLog(error, '会控异常|解除全体静音')
      }
    },

     /**
     * 全体手放下
     */
    async downAllHandHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.ALL_HAND_DOWN,
        }, this.$configs);

      } catch (error) {
        console.log(error);
        loganLog(error, '会控异常|全体手放下')
      }
    },
     /**
     * 收回主持人
     */
    async revokeHostHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.RECOVER_HOST_PERMISSION,
          // toPeerId: this.$configs.peerId,
        }, this.$configs)

      } catch (error) {
        console.log(error);
        loganLog(error, '会控异常|收回主持人')
      }
    },
     /**
     * 举手
     */
    async raiseHandHandle() {
      try {
        const selfId = this.$configs.peerId;

        await fetchMeetControl({
          command: CONTROL_COMMAND.RAISE_HAND,
          toPeerId: selfId,
        }, this.$configs);


        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error);
        loganLog(error, '会控异常|举手')
      }
    },

     /**
     * 成员主动将手放下
     */
    async downHandHandle() {
      try {
        const selfId = this.$configs.peerId;

        await fetchMeetControl({
          command: CONTROL_COMMAND.SINGLE_HAND_DOWN,
          toPeerId: selfId,
        }, this.$configs);

        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error); // TODO:
        loganLog(error, '会控异常|手放下')
      }
    },

     /**
     * 静音
     */
    async setMuteHandle(userId) {

      if (userId === this.selfInfo.userId) { // 自己执行静音操作

        console.log('操作自己')

        try {
          await this.sdk.rtc.muteLocalAudio(true)

          // 更改自身状态
          this.$store.commit('member/updateUser', {
            userId,
            isUseHuaTong: false
          })
        } catch (error) {
          loganLog({
            msg: '成员列表静音操作',
            userId: this.$configs.peerId,
            userName: this.selfInfo.userName,
            error
          }, '会控异常|自己静音')
        }
      } else { // 控制他人静音
        console.log('操作他人静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_MUTE,
            toPeerId: userId
          }, this.$configs)
        } catch (error) {
          console.log(error)
          loganLog(error, '会控异常|他人静音')
        }
      }
    },

    /**
     * 解除静音
     */
    async setUnMuteHandle(userId) {
      if (userId === this.selfInfo.userId) {

        console.log('操作自己解除静音')

        if (!this.selfInfo.isUseHuaTong && this.isOrdinaryUser && !this.allowSelfUnmute) { 
          const commonTipText = this.$t('meeting.noVoiceFail')
          this.$dialog.confirm({
            className: 'default-dialog',
            title: commonTipText,
            showCancelButton: false,
            confirmButtonText: this.$t('login.sure')
          })
          return
        }

        try {
          await this.sdk.rtc.muteLocalAudio(false)

          // 更改自身状态
          this.$store.commit('member/updateUser', {
            userId,
            isUseHuaTong: true
          })
        } catch (error) {
          loganLog({
            msg: '成员列表解除静音操作',
            userId: this.$configs.peerId,
            userName: this.selfInfo.userName,
            error
          }, '会控异常|自己解除静音')
        }
      } else { // 控制他人解除静音
        console.log('操作他人解除静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_UNMUTE,
            toPeerId: userId
          }, this.$configs)
        } catch (error) {
          console.log(error)
          loganLog(error, '会控异常|他人解除静音')
        }

      }
    },

    /**
     * 点击成为主持人
     */
    setBecomeHostHandle() {
      this.showBecomeHostWin = true
    },
 
    }
  }

</script>

<style lang="less" scoped>
.flex_center(@align:center, @justify:center) { 
  display: flex;
  align-items: @align;
  justify-content: @justify;
}


/********** 底部会控按钮************/
 .action {
  width: 100%;
  height: 182px;
  .flex_center(flex-start, flex-start);
  margin-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  .button {
    width: 210px;
    height: 74px;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.15);
    font-size: 28px;
    font-weight: 400;
    color: #1DAF69;
    padding: 0;
    margin-right: 20px;
  }
  .button.light {
    background: #1AB370;
    color: #FFFFFF;
    border:none;
  }
  .button:last-child {
    margin-right: 0;
  }
}

/********** 更多菜单弹窗 ************/
.menu-content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  .text {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }
}
.menu-content .text:last-child {
  border-bottom: none;
}
</style>
