


<template>

  <div 
    :class="contionalClass"
  >

      <span 
        class="collapse-fullscreen"
        @click.stop="collapseFullscreen"
      >

        <my-icon 
          iconName="icon-collapse-fullscreen" 
          fontSize="40"

        ></my-icon>

      </span>
      
      <div v-if="show" style="width:100%;height:100%">
          <local-video-item
            v-if="userMain.userId == $configs.peerId"
            :user="userMain"
            :key="'isLandscape-'+userMain.userId"
            :notHighlight='true'
          ></local-video-item>

          <video-item
            v-else
            :user="userMain"
            :speakerView="true"
            :key="'isLandscape-'+userMain.userId"
          ></video-item>
      </div>

  </div>

</template>


<script>

import VideoItem from './VideoItem.vue';
import LocalVideoItem from './LocalVideoItem.vue';


export default {
    components: {
      VideoItem,
      LocalVideoItem
    },
    data() {
      return {
        enlargeVideoShow: false,

        // isShare: false // 共享屏幕 默认横屏显示 
      }
    },

    computed: {
      contionalClass() {
        let _o = {
          "enlarge-video" : true
        };

        if(this.show) {
          _o.landscape = true;
        } else {
          _o.hide = true;
        }

        return _o 
      },

      show() {
        if(!this.userMain) return false;

        if(this.userMain.sharePaused) return false; // 共享暂停 直接收起

        const { isLandscape } = this.$store.state.meet;

        return isLandscape;

      },

      userMain() { // 发言的用户

        let userMain = this.$store.getters["member/userMain"];

        return userMain;
      },
      
    },

    methods: {
        
      collapseFullscreen() {
        this.$store.commit("meet/updateGlobalMeetState", {
          isLandscape: false
        })
      }

    },

    watch: {
      show(newVal) {
        if(!newVal) this.collapseFullscreen()
      },

      'userMain.isShare': function(newVal) {
        if(!newVal) this.collapseFullscreen();
      },

      'userMain.isUseShiPin': function(newVal) {
        if(!this.userMain) return;
        if(this.userMain.isShare) return;

        if(!newVal) this.collapseFullscreen();
      },

    }


}
</script>


<style lang="less" scoped>

  .enlarge-video {
    background: #222;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;

    transition: .5s;

    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
    }

    .collapse-fullscreen {

      position: absolute;
      bottom: 48px;
      right: 60px;
      z-index: 10;
      
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      transform: translateZ(0);

    }
  }

  .enlarge-video.hide {
    width:0;
    height: 0;

    transition: 0.5s;
  }

  .enlarge-video.landscape {
    width: 100vh;
    height: 100vw;

    transform: rotate(90deg) translateX(-100vw);
    transform-origin: 0% 100%;

    transition: 0.5s;
  }


</style>



