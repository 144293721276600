<template>

    <div>

      <popup-bottom
        :value="value"
        @input="val => this.$emit('input', val)"
      >
        <div class="content">
          <div class="tip">
            {{ selfInfo.roleCode == ROLE_CODE.HOST ?
            $t('meeting.endMeetingsTip') : $t('meeting.leaveMeetingsTip') }}
          </div>
          <div
            class="text"
            @click="leave"
          >
            {{$t('meeting.leaveMeetings')}}
          </div>

          <van-button
            :disabled="endMeetingStatus == 'loading'"
            :loading="endMeetingStatus == 'loading'"
            loading-type="spinner"
            :loading-text="$t('meeting.endMeetings')"
            block


            v-if="selfInfo.roleCode == ROLE_CODE.HOST && $configs.businessType != 10007"
            @click.stop="endMeeting"

          >
            {{$t('meeting.endMeetings')}}
          </van-button>

        </div>
      </popup-bottom>

      <!-- 倒计时弹窗 -->
      <van-dialog
        v-model="onexitDialog.show"
        :message="onexitDialog.message"
        :confirm-button-text="onexitDialog.confirmButtonText"
        @confirm="onexitConfirm"
        title="温馨提示"
      >

      </van-dialog>
    </div>


</template>
<script>
import PopUpBottom from '@/components/PopUpBottom/index';
import { ROLE_CODE } from "@/constant";
import { throttle } from 'lodash'
import { THROTLE_TIME, FACE_CACHE_KEY } from "@/constant/index";
import { loganLog } from '@/utils/log';
import { removeLocal } from '@/utils/tool'


export default {
  components: {
    'popup-bottom': PopUpBottom,
  },

  props: {
    value: {
        type: Boolean,
        default: false
      }
  },

  created() {
    this.sdk.on('onExitMeeting', this.onExitMeeting);
    this.$eventBus.$on('remove-self', () => this.onExitMeeting(4)); // 监听会控事件
  },

  data() {
    return {
      ROLE_CODE,
      leaveAction: false, // 是否正常退会 true: 正常离会
      endMeetingStatus: 'init', // init: 一开始的状态，loading: 请求中，error: 退会失败

      onexitDialog: {
        show: false,
        message: '',
        confirmButtonText: this.$t('login.know') + `${3}s`,
      },

      removeTime: 4,
      removeTimer: null
    };
  },

  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },

  },


  methods: {
    leave: throttle(async function(){
      this.leaveAction = true;

      loganLog('Exit.vue leave action');
      
      this.back();

      this.clearFaceLocal()

      this.sdk.rtc.leaveMeeting();

    }, THROTLE_TIME,{ leading: true, trailing: false}),

    endMeeting: throttle(async function(){
      this.leaveAction = true;

      try {
        this.endMeetingStatus = 'loading';
        await this.sdk.rtc.endMeeting();
        this.back();
        this.clearFaceLocal()
      } catch (error) {
        this.endMeetingStatus = 'error';

        this.$eventBus.$emit('end-meeting', error)
      }

    }, THROTLE_TIME,{ leading: true, trailing: false}),

    // 被动退出会议
    onExitMeeting(data) {

      let text_map = {
        1: this.$t('meeting.getOut'),//您已被主持人移出会议
        2: this.$t('meeting.overTimeMeet'),//主持人已结束会议3s
        3: this.$t('meeting.closeMeet'),//本次医百会议已结束3s
        4: this.$t('meeting.noMembership')//您已在其它设备加入,当前设备被移除会议
      }



      const reason = data == 4 ? text_map[data] : text_map[data.reason];

      this.onExitCountdown(reason)

    },

    onExitCountdown(reason) {
      if(this.removeTimer) return;

      this.leaveAction = true;

      this.removeTimer = setInterval(() => {
        this.removeTime--;
        console.log('this.removeTime', this.removeTime);
        if(this.removeTime == 0) {
          this.onexitConfirm()

        } else {
          this.onexitDialog = {
            show: true,
            message: reason,
            confirmButtonText: this.$t('login.know') + `${this.removeTime}s`,
          }
        }

      },1000)

    },

    onexitConfirm() {
      clearInterval(this.removeTimer);
      this.removeTimer = null;
      this.removeTime = 4;
      this.onexitDialog.show = false;

      this.back();
      this.clearFaceLocal(); //清除美颜缓存
      this.sdk.rtc.reset(); //重置SDK 数据
    },

    clearFaceLocal() {
      /**
       * 正常退会情况下：
       * 清除美颜相关的公共缓存 未登录用户不记忆
       * 清除开启美颜的缓存
      **/
      // removeLocal(FACE_CACHE_KEY)
      // removeLocal("isUseMeiYan")
    },

    back() {

      this.reset();
      
      loganLog('Exit.vue back fun')
      this.$emit('enableBack');
    },

    reset() {

      // 清空vuex中member, reset状态
      this.$store.commit("member/reset");
      this.$store.commit("meet/reset");
      this.$store.commit('resetMessageList')

      // reset $configs
      this.$configs.userId = ''
      this.$configs.roomId = ''
      this.$configs.userName = ''
      this.$configs.avatarUrl = ''
      this.$configs.peerId = ''
      this.$configs.conferenceNo = ''

    }

  },

  beforeDestroy() {

    this.reset();

    if(!this.leaveAction) {
      console.error('exit beforeDestroy !leaveAction');
      this.sdk.rtc.leaveMeeting(); // 非正常离开页面，退会
    }

  },

};
</script>

<style scoped  lang="less">
.content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  text-align: center;
  color: #000000;
  .tip {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 80px;
    border-bottom: 1px solid #eeeeee;
    color: #999;
  }
  .text {
    height: 96px;
    line-height: 96px;
    font-size: 32px;
    border-bottom: 1px solid #eeeeee;
  }
  .borderNone {
    border: none;
  }


  // vant button
  .van-button--default {
      color: #F35B67;
      background-color: #fff;
      border: none;

      font-size: 32px;
  }
}
</style>
