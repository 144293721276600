 <template>
  <div class="pop-content">
    <!-- 5s后自动消失 -->
   <span class='more_info' @click='showMore' v-show='showMoreBtn'>{{$t('meeting.moreInfo')}}
  </span>
    <div class="bullet_style">
      <div  v-for="(message) in hotsList" :key="message.id" :id="message.id">
        <div class='more' v-if="message.type == 1">
            <span class='name_style' v-if="message['uid'] === userID">{{$t('meeting.me')}}:</span>
            <span class='name_style' v-else>{{message.nick}}:</span>
              <span class="bullet-text"
                v-for="(item, index) in contentList(message.payload.text)"
                :key="index"
              >
                  <span :key="index" v-if="item.name === 'text'"   v-html="item.text"></span>
                <img v-else-if="item.name === 'img'" :src="require(`@/assets/emotion/${item.src}`)" width="14px"
                  height="14px" :key="index" />
              </span>
        </div>
      </div>
    </div>

    <div class="input_style">
      <my-icon fontSize="32" iconName="icon-message" :style="{'flex-shrink': 0}"></my-icon>
      <form  @submit.prevent="sendTextMessage('bullet')" action="javascript:return true">
        <input type="text"   :placeholder="$t('meeting.IMPlaceholder')" maxlength="300" v-model='chatContent' oninvalid="setCustomValidity('聊天输入内容最多不能超过300字')">
      </form>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import imMixins from '@/mixins/im.mixins.js'
  export default {
    mixins: [imMixins],
    components: {
    },
    data() {
      return {
        roomId: this.$route.query.roomID,
        nowDate: null,
        dateInterval: null,
        showMoreBtn: false,
      }
    },
    mounted() {
      this.nowDate = moment();
      this.dateInterval = setInterval(() => {
        this.nowDate = moment();
      }, 1000)
    },
    computed: {
      historyList() {
        return this.currentMessageList
      },

      hotsList() {
        const hots = this.currentMessageList.filter(item => {
          const diff = this.getDiff(item.receviceTime, this.nowDate)
          return  diff < 6
        })

        this.showMoreBtn = hots.length > 3
        return hots.slice(-3)
      },

    },

    methods: {
      getDiff(date1, date2) {
        // 直接用moment 进行比对
        return date2.diff(date1, 'seconds')
      },


      showMore(){
        //关闭新消息提醒
        this.$store.commit("meet/updateGlobalMeetState", {
          showNewChatTips: false
        })

        //弹聊天窗口
        this.$emit('open-chat')
      },
      chatClose() {
        //关闭聊天窗口
        this.$emit('close-chat')
      }
    },

    beforeDestroy() {
      clearInterval(this.dateInterval)
    }
  }

</script>
<style lang="less" scoped>
  .public_style() {
    background: rgba(0, 0, 0, .45);
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 6px 16px;
    /* line-height: 40px; */
  }

  .pop-content {
    .more_info{
      .public_style();
      width:174px;
      font-size: 20px;
      color: rgba(255, 255, 255, .7);
      display: flex;
    }
    .bullet_style {
      max-width: 65%;
      max-height:460px;
      overflow-y: auto;
      overflow-x: hidden;

      .more {
        .public_style();
        display: inline-block;
        font-size: 20px;
        color: rgba(255, 255, 255, .7);
        max-width:460px;

          .name_style {
              min-width:20px;
              display: inline-block;
              color: rgba(250, 173, 20, .7);
              margin-right: 6px;
              max-width: 446px;
              overflow: hidden;/*超出部分隐藏*/
              white-space: nowrap;/*规定段落中的文本不进行换行 */
              text-overflow: ellipsis;/* 超出部分显示省略号 */
              -o-text-overflow: ellipsis;/* 超出部分显示省略号 */
              vertical-align: text-bottom;
          }
        .bullet-text{
          font-size: 20px;
          line-height:40px;
          color: rgba(255, 255, 255, 0.8);
          }
      }
    }

    .input_style {
      .public_style();
      width: 280px;
      height: 64px;
      border-radius: 36px;
      display: flex;
      align-items: center;
      color: #b1abab;
      margin-top:10px;

      input {
        width: 185px;
        height: 33px;
        margin-left: 16px;
        border: none;
        background: transparent;
      }
    }
  }

</style>
